import React from "react";
import Ample from "../components/ample/ample";
import {formatCurrency} from "../utils";
import ExpirySelector from "../components/expiryselector";
import TickerDropDown from "../components/tickerdropdown";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { OutboundLink } from "gatsby-plugin-google-gtag";
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export default class MaxPainPage extends React.Component {
  state = {
    ticker: "CHWY",
    expiry: "",
    resultsVisible : false,
    optiondata: [],
    backdropActive:false,
    instructionsExpanded:true
  }
  SortMode = {
    Strike:0, PutInterest:1, CallInterest:2, CumulativeLiability:3
  }
  formValid() {
    return ((this.state.ticker.trim() !== "") && (this.state.expiry.trim() !== ""))
  }
  tickerChanged = newTicker => {
    this.setState({ticker:newTicker})
  }
  expiryChanged = newExpiry => {
      this.setState({expiry:newExpiry})
  }

  
  formReset = event => {
    this.setState({resultsVisible:false})
  }
  formSubmitted = event => {
    this.requestOptionData()
  }
  requestOptionData() {
    const url = `${process.env.GATSBY_TICKER_API_ENDPOINT}/${this.state.ticker}/max-pain?expiry=${this.state.expiry}`
    
    console.log(url)
    this.setState({ resultsVisible:false, backdropActive:true}
                  ,()=>fetch(url)
                    .then(res => res.json())
                    .then((data) => this.setState({ optiondata: data }))
                    .then(()=>{this.setState({resultsVisible:true,backdropActive:false})})
                    //.then(()=>this.sortData(this.SortMode.CumulativeLiability))
                    .catch(console.log)
                  );
  }
  sortData(mode) {
      let data = this.state.optiondata
      switch (mode) {
        case this.SortMode.Strike:
          data.sort( (a,b) => b.strike - a.strike )
          break
        case this.SortMode.PutInterest:
          data.sort( (a,b) => b.putInterest - (a.putInterest)  )
          break
        case this.SortMode.CallInterest:
          data.sort( (a,b) =>  (b.callInterest) - (a.callInterest))
          break
        default:
          data.sort( (a,b) => a.CumulativeLiability - b.CumulativeLiability )
      }
      this.setState({optiondata:data})
  }
  requestSort = (mode) => {
      this.sortData(mode)
  }
   
  optionTable()  {
    let data=this.state.optiondata;
    let maxPainStyle = {
      backgroundColor:'#545b62',
      color: '#ffffff'
    }
    let optiondatatable= (
      <Card>
      <CardContent>
        <table width="100%" className="table">
        <thead>
          <tr>
            <th className="border-top-0" onClick={() => this.requestSort(this.SortMode.Strike)}>Strike&nbsp;<i className="fas fa-sort"></i></th>
            <th className="border-top-0" onClick={() => this.requestSort(this.SortMode.CallInterest)}>Call Interest&nbsp;<i className="fas fa-sort"></i></th>
            <th className="border-top-0" onClick={() => this.requestSort(this.SortMode.PutInterest)}>Put Interest&nbsp;<i className="fas fa-sort"></i></th>
            <th className="border-top-0" onClick={() => this.requestSort(this.SortMode.CumulativeLiability)}>Cumulative Liability&nbsp;<i className="fas fa-sort"></i></th>
            </tr>
        </thead>
        <tbody>
        {data.map(opt=> {
            return (<tr key={opt.strike} style={ opt.IsMaxPain ? maxPainStyle : {}  }>
            <td>{opt.strike}</td>
            <td>{opt.callInterest}</td>
            <td>{opt.putInterest}</td>
            <td>{formatCurrency(opt.CumulativeLiability,0)}</td>
            </tr>)
          })}
        </tbody>
        </table>
      </CardContent>
      </Card>

    )
    return optiondatatable;
  }
  
  searchCard = () => {
    return (
    <Card>
      <CardContent>
        <table>
        <tbody>
          <tr>
            <td><TickerDropDown OptionsOnly={true} defaultTicker={this.state.ticker}  onSelectedTickerChanged={this.tickerChanged} helperText="Select underlying company" aria-label="Select underlying company"/></td>
            <td><ExpirySelector ticker={this.state.ticker} id="optionExpirySelector" onSelectedExpiryChange={this.expiryChanged} aria-label="Select expiry date"/></td>
          </tr>
        </tbody>
        </table>
        <br/>
        
        <button disabled={!this.formValid()} id="go" onClick={this.formSubmitted}  type="submit"  className="btn btn-rounded btn-default btn-outline">
          <i className="fas fa-play-circle  m-r-5"></i>Show</button>
        <button disabled={!this.state.resultsVisible} id="clear" onClick={this.formReset} className="btn btn-rounded btn-default btn-outline" >
            <i className="fas fa-eraser  m-r-5"></i>Clear</button>
      </CardContent>
    </Card>);
  }
  toggleInstructions = () => {
    const expand=(this.state.instructionsExpanded===false); 
    this.setState({instructionsExpanded:expand});
  }
  explainerCard = () => {
    return (
      <Card >
      <CardContent className="font-light"  style={{paddingBottom:0,paddingTop:0}} >
      <Table width={"100%"} sx={{[`& .${tableCellClasses.root}`]: {p: .25,borderBottom: "none"}}}>
        <TableRow>
          <TableCell>
          The <b>Max Pain Calculator</b> is based on the <OutboundLink href="https://www.investopedia.com/terms/m/maxpain.asp">maximum pain theory</OutboundLink> which states that a stock's price tend towards the price at which there is the most option liability in the market.
          </TableCell>
          <TableCell>
            <IconButton size="inherit" aria-label="show instructions" onClick={()=>this.toggleInstructions()} sx={{transform:(!this.state.instructionsExpanded ? 'rotate(0deg)' : 'rotate(180deg)')}}>
              <ExpandMoreIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </Table>
      </CardContent>
      <CardContent  className="font-light"  style={{paddingBottom:0,paddingTop:"4px"}}>
      <Collapse in={this.state.instructionsExpanded===true}>
        <p>
        <h2 className="font-medium font-14">Why Use It</h2>
        The tool can be helpful in devising trading strategies where you wish to see how much option liability exists in the market at each strike. It highlights the 'max pain' strike.
        </p>
        
        <p>
        <h2 className="font-medium font-14">How to Use It</h2>
        Select a stock from the <b>Ticker</b> selector, and an expiration date from the <b>Expiry</b> selector. Press the <i className="fas fa-play-circle  m-r-5"></i> button to calculate the cumulative liability at each strike.
        </p>
        <p>
          <h2 className="font-medium font-14">How It Works</h2>
          The tool calculates, for each strike in the chain, the total value of all of the options which would expire worthless if the stock were to close at that strike on expiration day. This is based on the current <OutboundLink href="https://www.investopedia.com/terms/o/openinterest.asp">open interest</OutboundLink> at each strike.
        </p>
      </Collapse>
      </CardContent>
      </Card>);
  }

  seoDescription="MaxPain calculator. Calculate the max pain strike for over 3000 stocks. See the open interest and cumulative liability at each strike.";

  render() {
    return (
    <Ample currPageTitle="Max Pain Calculator"  seoDescription={this.seoDescription}  location={this.props.location.href}>
        
      <Backdrop style={{zIndex:  1,color: '#fff',}} open={this.state.backdropActive}  >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container spacing={2}>
        <Grid item  lg={12}  >
          {this.explainerCard()}
        </Grid>

        <Grid item lg={12} sx={{width:1, justifyContent:'right'}}>
            {this.searchCard()}
        </Grid>

        <Grid item lg={12} style={{visibility:(this.state.resultsVisible)?'visible':'hidden'}}>
        {this.optionTable()}
        </Grid>
      </Grid>

      </Ample>
    )
  }
}


